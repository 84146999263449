// react
import React from 'react';

/**
 * AppLogo
 */

interface Props {
    type?: string
}

export const AppLogo = (props: Props) => {
    // setup
    const src = require((props.type === 'logo') ? "../assets/fltv-logo.png" : "../assets/fltv-full.png");
    const style = `app-logo ${props.type}`;

    // render
    return <img className={style} alt='logo' src={src}/>
}
