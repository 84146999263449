// react
import React, {useRef} from 'react';
import {AppLogo, Button} from "../components";
import {SysStr} from "../types";
import parse from "html-react-parser";

/**
 * IntroScreen
 */

interface ScrollProps {
  text: string
}

const ScrollText = (props: ScrollProps) => {

  const htmlRef = useRef<HTMLDivElement | null>(null);
  const {text} = props;
  let position = 0;

  function scrollDown(element: HTMLDivElement) {
    // setup
    const height = Math.round(parseFloat(window.getComputedStyle(element).fontSize) * 2);
    const scrollMax = element.scrollHeight - element.clientHeight;

    // Determine the scroll state.
    if (position < scrollMax) {
      let scroll = position + height;
      element.scrollTo(0, scroll);
    }
  }

  function scrollUp(element: HTMLDivElement) {
    // setup
    const height = Math.round(parseFloat(window.getComputedStyle(element).fontSize) * 2);

    // Determine the scroll state.
    if (position > 0) {
      let scroll = position - height;
      element.scrollTo(0, scroll);
    }
  }

  // render
  return <div className='html-scroll'>

    <div className='html-block' ref={htmlRef}>
      {parse(text)}
    </div>

    <div className='html-buttons'>
      <button className='up-button' onClick={() => {
        if (htmlRef.current)
          scrollUp(htmlRef.current);
      }}/>
      <button className='down-button' onClick={() => {
        if (htmlRef.current)
          scrollDown(htmlRef.current);
      }}/>
    </div>

  </div>
}

interface ViewProps {
  onNavigate: (action: string) => void
}
interface ViewState {
}

export class HelpScreen extends React.Component<ViewProps, ViewState> {

  render()
  {
    // setup
    const {onNavigate} = this.props;
    const {title, description} = SysStr.help;
    const {ok} = SysStr.button;

    // render
    return <div className='page'>

      <div className='header'>
        <text className='title'>{title}</text>
      </div>

      <ScrollText text={description}/>

      <div>
        <Button title={ok} action='ok' onClick={() => onNavigate('ok')}/>
      </div>

    </div>
  }

}
