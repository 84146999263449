// react
import axios from "axios";

// local
import {UserInfo, DmaInfo, ChannelInfo} from "./models";
import {SysDebug} from "./debug";

export const apiservice = axios.create({
	baseURL: 'https://dma.freelocaltv.org/',
	url: 'dv1',
	method: 'POST',
	withCredentials: false,
	//headers: {'Cookie': 'XDEBUG_SESSION=11475'}
});

export let WebService = (function () {

	function getUri(user: UserInfo) {
		return `/${user.appId}/${user.zipcode}/d${user.age}-${user.gender}`;
	}

	const dmaRequest = async (user: UserInfo): Promise<DmaInfo | null>  => {
		// setup
		const uri = 'dv1' + getUri(user) + '/dma';
		try {
			const results = await apiservice.get(uri);
			SysDebug.log('WebService', 'dma', uri, results);
			return (results.status === 200) ? results.data : null;
		} catch (error) {
			SysDebug.log('WebService', 'dma', uri, error);
			return null;
		}
	}

	const epgRequest = async (user: UserInfo): Promise<ChannelInfo[]>  => {
		// setup
		const uri = 'ev1' + getUri(user) + '/channels';
		try {
			const results = await apiservice.get(uri);
			SysDebug.log('WebService', 'epg', uri, results);
			return (results.status === 200) ? results.data.channels : [];
		} catch (error) {
			SysDebug.log('WebService', 'epg', uri, error);
			return [];
		}
	}

	return {
		dmaRequest,
		epgRequest,
		getUri
	}

})();
