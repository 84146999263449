// react
import moment from 'moment';
import {STORAGE_MAX_LOGS} from "../variables";

/**
 * SystemDebug - System debugging module used to log navigation, results from API calls, and errors.
 * There are two levels to the debug - the run-time console logging and the storage of log information for
 * presentation in the app or for posting to the back-end system.
 */

const components = [
	// pages
	"App",
	"WebService"
];

export interface DebugLog {
	timestamp: string,
	direction: string,
	component: string,
	module: string,
	data: any // is an ellipsis value (arrays, strings, objects)
}

export let SysDebug = (function () {

	let debugLogs: DebugLog[] = [];

	/**
	 * Clear all the current logs in memory.
	 */
	function clearLogs() {
		// Reset the global variable.
		debugLogs = [];
	}

	/**
	 * Log an event, error, or status update.
	 * @param component is the name of the component (e.g. MdlCourse, WebService, ...)
	 * @param module is the name of the function (e.g. bn_get_strings, mdl_get_courses, ...)
	 * @param direction identifies one of the following (in-API call, out-API results, app-internal to the APP)
	 * @param data is an ellipses value that contains the raw data.
	 */
	function log(component: string, module: string, direction: string, ...data: any) {
		// Make sure we want to create the log.
		if (!components.includes(component)) {
			return;
		}

		// Record the debug message.
		const timestamp = moment().format('hh:mm:ss');
		if (!data || !data.length) {
			console.log(component, module, direction);
		} else {
			console.log(component, module, direction, data);
		}

		// Add to the debug logs.
		debugLogs.unshift({timestamp, direction, component, module, data});
		if (debugLogs.length > STORAGE_MAX_LOGS) {
			debugLogs.pop();
		}
	}

	/**
	 * PUBLIC ACCESS
	 */

	return {
		clearLogs: clearLogs,
		getLogs: function() { return debugLogs; },
		log: log
	}

})();
