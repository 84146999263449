// react
import React from 'react';
import {SysStr} from "../types";

/**
 * Button
 */

interface ItemProps {
    title: string,
    action: string,
    onClick: (action: string) => void
}
const KeypadItem = (props: ItemProps) => {
    // setup
    const {action, title, onClick} = props;

    // render
    return <button className='keypad-item' onClick={() => onClick(action)}>
        {title}
    </button>
}

interface ViewProps {
    keySelect: (value: string) => void
}
export const Keypad = (props: ViewProps) => {
    // setup
    const vKeys = SysStr.keyboard.map(item => {
        return <KeypadItem title={item.value} action={item.id} onClick={onUpdate}/>
    });

    function onUpdate(value: string) {
        props.keySelect(value);
    }

    // render
    return <div className='keypad'>
        {vKeys}
    </div>
}
