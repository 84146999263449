// react
import React from 'react';

// local
import './App.css';
import "./pages/pages.css";

import {ChannelInfo, DmaInfo, SysStr, System, UserInfo, WebService} from "./types";
import {
  AgeScreen,
  EpgScreen,
  GenderScreen, HelpScreen,
  PlayerScreen,
  ProfileScreen, RegisteredSplash,
  TermsOfService,
  UnregisteredSplash,
  ZipcodeScreen
} from "./pages";
import {ErrorPopup} from "./modals";
import {LOCAL_STORAGE, USER_SETTINGS} from "./variables";

const defaultUser = {
  appId: '',
  zipcode: '',
  gender: '',
  age: '',
  channel: '',
  tos: false
};

interface IAppProps {
}
interface IAppState {
  loaded: boolean,
  page: string,
  user: UserInfo,
  dma: DmaInfo | null,
  channels: ChannelInfo[],
  errorTitle: string | null,
  errorDescription: string | null
}

class App extends React.Component<IAppProps, IAppState> {

  constructor(props: IAppProps) {
    super(props);

    this.state = {
      loaded: false,
      page: '',
      user: defaultUser,
      dma: null,
      channels: [],
      errorTitle: null,
      errorDescription: null
    };

    // Register the callbacks.
    this.onDismissError = this.onDismissError.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
    this.onUpdateSettings = this.onUpdateSettings.bind(this);
  }

  componentDidMount = async () => {
    // Get any stored user information.
    let user = System.getStorage(USER_SETTINGS) as UserInfo;
    if (user) {
      const dma = await WebService.dmaRequest(user);
      const channels = await WebService.epgRequest(user);
      this.setState({user, dma, channels, page: 'RegisteredSplash', loaded: true});
    } else {
      this.setState({page: 'UnregisteredSplash', loaded: true});
    }
  }

  onDismissError(action: string) {
    // Process error actions.
    if (action === 'exit') {
      this.setState({errorTitle: null, errorDescription: null, page: 'UnregisteredSplash'})
    }
    this.setState({errorTitle: null, errorDescription: null});
  }

  onNavigate = async (action: string) => {
    // setup
    let {user} = this.state;

    // Determine the navigation flow.
    let page = null;
    switch (this.state.page) {
        // Step 1:
      case 'UnregisteredSplash':
        if (action === 'ok') {
          page = 'ZipcodeScreen';
        } else {
          page = 'LaunchScreen';
        }
        break;
      case 'RegisteredSplash':
        if (action !== 'ok') {
          page = 'UnregisteredSplash';
        } else if (user.channel) {
          page = 'PlayerScreen';
        } else {
          page = 'EpgScreen';
        }
        break;

        // Step 2:
      case 'ZipcodeScreen':
        if (action === 'complete') {
          page = user.tos ? 'ProfileScreen' : 'AgeScreen';
        }
        break;
      case 'AgeScreen':
        if (action === 'complete') {
          page = user.tos ? 'ProfileScreen' : 'GenderScreen';
        }
        break;
      case 'GenderScreen':
        if (action === 'complete') {
          page = user.tos ? 'ProfileScreen' : 'TermsOfService';
        }
        break;
      case 'HelpScreen':
        page = 'ProfileScreen';
        break;

      case 'ProfileScreen':
        if (action === 'zipcode') {
          page = 'ZipcodeScreen';
        } else if (action === 'age') {
          page = 'AgeScreen';
        } else if (action === 'gender') {
          page = 'GenderScreen';
        } else if (action === 'help') {
          page = 'HelpScreen';
        } else if (user.tos) {
          page = 'PlayerScreen';
        } else {
          page = 'TermsOfService';
        }
        break;

        // Step 3:
      case 'TermsOfService':
        if (action === 'exit') {
          page = 'LaunchScreen';
        } else {
          // Update the user and system information.
          user.appId = 'f08ba3164b214490b549701894f00b96';
          user.tos = true;
          const dma = await WebService.dmaRequest(user);
          const channels = await WebService.epgRequest(user);
          System.setStorage(LOCAL_STORAGE, USER_SETTINGS, user);
          this.setState({user, dma, channels});

          // Update the navigation.
          page = 'EpgScreen';
        }
        break;

        // Step 4:
      case 'EpgScreen':
        user.channel = action;
        System.setStorage(LOCAL_STORAGE, USER_SETTINGS, user);
        this.setState({user});
        page = 'PlayerScreen';
        break;

        // Step 5
      case 'PlayerScreen':
        if (action === 'profile') {
          page = 'ProfileScreen';
        } else if (action === 'epg') {
          page = 'EpgScreen';
        } else if (action === 'clear-user') {
          System.clearStorage(USER_SETTINGS);
          this.setState({user: defaultUser});
          page = 'UnregisteredSplash';
        }
        break;
    }

    // Update the settings.
    if (page) {
      this.setState({page});
    }
  }

  onUpdateSettings = async (field: string, value: string): Promise<boolean> => {
    // setup
    let user = this.state.user;
    const {invalid} = SysStr.zipcode;

    // Update the user information.
    if (field === 'zipcode') {
      user.zipcode = value;

      // Temporary settings to test the user access.
      let testUser = {...user};
      testUser.gender = 'm';
      testUser.age = '1';
      testUser.appId = 'f08ba3164b214490b549701894f00b96';
      if (!await WebService.dmaRequest(testUser)) {
        this.setState({errorTitle: value, errorDescription: invalid})
        return false;
      }
    } else if (field === 'age') {
      user.age = value;
    } else if (field === 'gender') {
      user.gender = value;
    }

    // update
    this.setState({user});
    return true;
  }

  render()
  {
    // setup
    const {loaded, page, user, channels,
      errorTitle, errorDescription} = this.state;

    // Make sure we are loaded.
    if (!loaded) {
      return <div className={'page'}/>
    }

    // Determine the screen
    let view;
    if (page === 'UnregisteredSplash')
      view = <UnregisteredSplash onNavigate={this.onNavigate}/>
    else if (page === 'RegisteredSplash')
      view = <RegisteredSplash onNavigate={this.onNavigate}/>
    else if (page === 'ZipcodeScreen')
      view = <ZipcodeScreen user={user} onNavigate={this.onNavigate} onUpdate={this.onUpdateSettings}/>
    else if (page === 'AgeScreen')
      view = <AgeScreen user={user} onNavigate={this.onNavigate} onUpdate={this.onUpdateSettings}/>
    else if (page === 'GenderScreen')
      view = <GenderScreen user={user} onNavigate={this.onNavigate} onUpdate={this.onUpdateSettings}/>
    else if (page === 'ProfileScreen')
      view = <ProfileScreen user={user} onNavigate={this.onNavigate}/>

    // other
    else if (page === 'HelpScreen')
      view = <HelpScreen onNavigate={this.onNavigate}/>
    else if (page === 'TermsOfService')
      view = <TermsOfService onNavigate={this.onNavigate}/>

    // epg/player
    if (page === 'EpgScreen')
      view = <EpgScreen user={user} channels={channels} onNavigate={this.onNavigate}/>
    else if (page === 'PlayerScreen')
      view = <PlayerScreen user={user} channels={channels} onNavigate={this.onNavigate}/>

    // render
    return <>
      {view}
      {errorTitle && errorDescription && <ErrorPopup title={errorTitle} description={errorDescription} onDismiss={this.onDismissError}/>}
    </>
  }
}

export default App;
