// react
import React, {useEffect} from 'react';
import parse from 'html-react-parser';

// local
import {AppLogo, Button} from "../components";
import {SysStr} from "../types";
import {SPLASH_TIMEOUT} from "../variables";

/**
 * SplashScreen
 */

interface ViewProps {
  onNavigate: (action: string) => void
}
interface ViewState {
  timer: any
}

export class RegisteredSplash extends React.Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);
    this.state = {
      timer: null
    };
  }

  componentDidMount = async () => {
    // setup
    const {onNavigate} = this.props;

    // Only show screen for a couple seconds.
    if (!this.state.timer) {
      const timer = setTimeout(() => {
        clearTimeout(this.state.timer);
        this.setState({timer: null});
        onNavigate('ok');
      }, SPLASH_TIMEOUT);
      this.setState({timer});
    }
  }

  render()
  {
    // setup
    const {registered} = SysStr;

    // render
    return <div className='page top'>

      <div className='block center'>
        <AppLogo type='full'/>
        <text>{parse(registered.description)}</text>
      </div>

      <div className='block'>
        <text>{parse(registered.url)}</text>
      </div>

    </div>
  }

}
