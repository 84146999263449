// react
import React from 'react';

// local
import {AppLogo, Button, Progress} from "../components";
import {SysStr, UserInfo} from "../types";

/**
 * SplashScreen
 */

interface ViewProps {
  user: UserInfo,
  onNavigate: (action: string) => void
}
interface ViewState {
  loaded: boolean
}

const ProfileInfo = (props: ViewProps) => {
  // setup
  const {user} = props;
  const age = SysStr.age.options.find(item => item.id === user.age);
  const gender = SysStr.gender.options.find(item => item.id === user.gender);
  const edit = require("../assets/edit.png");

  function onNavigate(action: string) {
    props.onNavigate(action);
  }

  // render
  return <div className='body split'>

    <div className='block left'>
      <img className='profile-logo' alt='profile-logo' src={require("../assets/profile.png")}/>
    </div>

    <div className='block-right'>
      <text className='field-title'>{SysStr.zipcode.title}</text>
      <div className='button-wrapper' onClick={() => onNavigate('zipcode')}>
        <Button className='list-item bold' title={user.zipcode} action='zipcode' onClick={onNavigate}/>
        <img className='edit' src={edit} alt='edit-zipcode'/>
      </div>

      <text className='field-title'>{SysStr.age.title}</text>
      <div className='button-wrapper' onClick={() => onNavigate('age')}>
        <Button className='list-item bold' title={age?.value ? age?.value : ''} action='age' onClick={onNavigate}/>
        <img className='edit' src={edit} alt='edit-zipcode'/>
      </div>

      <text className='field-title'>{SysStr.gender.title}</text>
      <div className='button-wrapper' onClick={() => onNavigate('gender')}>
        <Button className='list-item bold' title={gender?.value ? gender?.value : ''} action='gender'
                onClick={onNavigate}/>
        <img className='edit' src={edit} alt='edit-zipcode'/>
      </div>

    </div>

  </div>
}

export class ProfileScreen extends React.Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);
    this.state = {
      loaded: false
    };
  }

  render()
  {
    // setup
    const {user, onNavigate} = this.props;
    const {cancel, ok, help} = SysStr.button;

    // render
    return <div className='page'>

      <ProfileInfo onNavigate={onNavigate} user={user}/>

      <div className='footer row'>
        <Button title={cancel} action='cancel' onClick={onNavigate}/>
        <Button title={ok} className='highlight' action='ok' onClick={onNavigate}/>
        <Button title={help} className='help' action='help' onClick={onNavigate}/>
      </div>

    </div>
  }

}
