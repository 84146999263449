// react
import React, {useRef} from 'react';
import parse from 'html-react-parser';

// local
import {AppLogo, Button} from "../components";
import {SysStr} from "../types";

/**
 * TermsOfService
 */

interface ScrollProps {
  text: string,
  position: number,
  onScroll: (position: number, maxY: number) => void
}

const ScrollText = (props: ScrollProps) => {

  const {text, position, onScroll} = props;
  const htmlRef = useRef<HTMLDivElement | null>(null);

  function scrollDown(element: HTMLDivElement) {
    // setup
    const height = Math.round(parseFloat(window.getComputedStyle(element).fontSize) * 2);
    const scrollMax = element.scrollHeight - element.clientHeight - height;

    // Determine the scroll state.
    if (position < scrollMax) {
      let scroll = position + height;
      element.scrollTo(0, scroll);
      onScroll(scroll, scrollMax)
    } else {
      onScroll(position, scrollMax);
    }
  }

  function scrollUp(element: HTMLDivElement) {
    // setup
    const height = Math.round(parseFloat(window.getComputedStyle(element).fontSize) * 2);

    // Determine the scroll state.
    if (position > 0) {
      let scroll = position - height;
      element.scrollTo(0, scroll);
      onScroll(scroll, scroll)
    }
  }

  // render
  return <div className='html-scroll'>

    <div className='html-block' ref={htmlRef}>
      {parse(text)}
    </div>

    <div className='html-buttons'>
      <button className='up-button' onClick={() => {
        if (htmlRef.current)
          scrollUp(htmlRef.current);
      }}/>
      <button className='down-button' onClick={() => {
        if (htmlRef.current)
          scrollDown(htmlRef.current);
      }}/>
    </div>

  </div>
}

interface ViewProps {
  onNavigate: (action: string) => void
}
interface ViewState {
  position: number,
  complete: boolean
}

export class TermsOfService extends React.Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);

    this.state = {
      position: 0,
      complete: false
    };

    // Register the callbacks.
    this.onScroll = this.onScroll.bind(this);
  }

  onScroll(position: number, maxY: number) {
    // setup
    const complete = (position > maxY);
    if (complete && !this.state.complete) {
      this.setState({position, complete});
    } else {
      this.setState({position});
    }
  }

  render() {
    // setup
    const {onNavigate} = this.props;
    const {position, complete} = this.state;
    const {title, description} = SysStr.tos;
    const {agree, exit} = SysStr.button;

    // render
    return <div className='page'>

      <div className='header'>
        <text className='title'>{title}</text>
      </div>

      <ScrollText text={description} position={position} onScroll={this.onScroll}/>

      <div>
        <Button title={exit} action='exit' onClick={onNavigate}/>
        <Button className={complete ? 'highlight' : ''} title={agree} action='ok' onClick={onNavigate}/>
      </div>

    </div>
  }

}
