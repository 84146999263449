// react
import React from 'react';
import {AppLogo, Keypad, Progress} from "../components";
import {SysStr, UserInfo} from "../types";

/**
 * SplashScreen
 */

interface ZipProps {
  keySelect: (value: string) => void
  value: string
}

const ZipInput = (props: ZipProps) => {
  // setup
  const {keySelect, value} = props;

  // render
  return <div className='body'>
    <div className='hz-center'>
      <input value={value}/>
      <Keypad keySelect={keySelect}/>
    </div>
  </div>
}

interface ViewProps {
  user: UserInfo,
  onNavigate: (action: string) => void
  onUpdate: (field: string, value: string) => Promise<boolean>
}
interface ViewState {
  zipcode: string;
}

export class ZipcodeScreen extends React.Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);

    this.state = {
        zipcode: props.user.zipcode
    };

    // Register the callbacks.
    this.onSelectKey = this.onSelectKey.bind(this);
  }

  onSelectKey = async (value: string) => {
    // setup
    const {onNavigate, onUpdate} = this.props;
    let zipcode = this.state.zipcode;

    if (value === 'enter') {
      if (await onUpdate('zipcode', zipcode)) {
        onNavigate('complete');
      }
    } else if (value === 'delete') {
      zipcode = zipcode.slice(0, -1);
    } else if (zipcode.length < 5) {
      zipcode += value;
    }
    this.setState({zipcode});
  }

  render()
  {
    // setup
    const {zipcode} = this.state;
    const {description} = SysStr.zipcode;

    // render
    return <div className='page'>

      <div className='header'>
        <text className='title'>{description}</text>
      </div>

      <ZipInput keySelect={this.onSelectKey} value={zipcode}/>

      <div className='footer'>
        <Progress step={1}/>
        <AppLogo/>
      </div>

    </div>
  }

}
