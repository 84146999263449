// react
import React from 'react';
import {Button} from "../components";

/**
 * ErrorModal
 */

interface ViewProps {
  title: string,
  description: string,
  onDismiss: (action: string) => void
}

export const ErrorPopup = (props: ViewProps) => {
  // setup
  const {title, description, onDismiss} = props;

  // render
  return <div className='page'>
    <div className='popup'>
      <div className='textarea'>
        <text className='title'>{title}</text>
        <text className='description'>{description}</text>
      </div>

      <div className='section'>
        <Button title='Exit' action='exit' onClick={() => onDismiss('exit')}/>
        <Button title='OK' action='ok' onClick={() => onDismiss('ok')}/>
      </div>
    </div>
  </div>
}
