// react
import React from 'react';

/**
 * Button
 */

interface ViewProps {
    title: string,
    action: string,
    onClick: (action: string) => void
}

export const ListItem = (props: ViewProps) => {
    // setup
    const {action, title, onClick} = props;

    // render
    return <button className='list-item' onClick={() => onClick(action)}>
        {title}
    </button>
}
