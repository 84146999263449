// react
import React from 'react';
import parse from 'html-react-parser';

// local
import {AppLogo, Button} from "../components";
import {SysStr} from "../types";

/**
 * SplashScreen
 */

interface ViewProps {
  onNavigate: (action: string) => void
}
interface ViewState {
  loaded: boolean,
  step: number
}

export class UnregisteredSplash extends React.Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);
    this.state = {
      loaded: false,
      step: 0
    };
  }

  render()
  {
    // setup
    const {onNavigate} = this.props;
    const {start} = SysStr.button;
    const {unregistered} = SysStr;

    // render
    return <div className='page top'>

      <div className='block'>
        <AppLogo type='full'/>
        <text>{parse(unregistered.description)}</text>
      </div>

      <div className='block'>
        <Button className='large highlight' title={start} action='ok' onClick={onNavigate}/>
      </div>

      <div className='block center'>
        <text>{parse(unregistered.disclaimer)}</text>
        <text>{parse(unregistered.url)}</text>
      </div>

    </div>
  }

}
