/* Storage */
export const LOCAL_STORAGE = "local";
export const SESSION_STORAGE = "session";

export const USER_SETTINGS = "fltv-app.user";

/* Logs */
export const STORAGE_MAX_LOGS = 100;

/* other */
export const SPLASH_TIMEOUT = 2000;
