// react
import React from 'react';
import moment from 'moment';

// local
import {ChannelInfo, ShowInfo, UserInfo} from "../types";

/**
 * IntroScreen
 */

interface ViewProps {
  user: UserInfo,
  channels: ChannelInfo[],
  onNavigate: (action: string) => void
}
interface ViewState {
  channel: ChannelInfo | null,
  show: ShowInfo | null
}

export class EpgScreen extends React.Component<ViewProps, ViewState> {

  constructor(props: ViewProps) {
    super(props);

    const {user, channels} = props;
    const channel = channels.find(channel => channel.affiliation === user.channel);

    this.state = {
      channel: channel ? channel : null,
      show: channel?.shows.length ? channel.shows[0] : null
    };

    // Register the callbacks.
    this.keyDownHandler = this.keyDownHandler.bind(this);
    this.onSelectShow = this.onSelectShow.bind(this);
    this.onHighlightShow = this.onHighlightShow.bind(this);
  }

  componentDidMount = async () => {
    window.addEventListener('keydown', this.keyDownHandler);
  }

  componentWillUnmount = () => {
    window.removeEventListener('keydown', this.keyDownHandler);
  }

  keyDownHandler(event: KeyboardEvent) {
    // setup
    const {channels} = this.props;
    const {channel, show} = this.state;
    const shows = channel ? channel.shows : [];
    const channel_number = channel?.channel_number;
    const show_id = show?.id;

    const cIndex = channels.findIndex(obj => obj.channel_number === channel_number);
    const sIndex = shows.findIndex(obj => obj.id === show_id);

    // Table navigation.
    if (event.key === 'ArrowDown' && cIndex < channels.length - 1) {
      const channel = channels[cIndex+1];
      this.setState({channel, show: channel.shows[0]});
    } else if (event.key === 'ArrowUp' && cIndex > 0) {
      const channel = channels[cIndex-1];
      this.setState({channel, show: channel.shows[0]});
    } else if (event.key === 'ArrowRight' && sIndex < shows.length - 1) {
      this.setState({show: shows[sIndex+1]});
    } else if (event.key === 'ArrowLeft' && sIndex > 0) {
      this.setState({show: shows[sIndex-1]});
    }

    event.stopPropagation();
  }

  onHighlightShow(channel: ChannelInfo, show: ShowInfo) {
    // setup
    const channel_number = this.state.channel?.channel_number;
    const show_id = this.state.show?.id;

    if (channel.channel_number !== channel_number || show.id !== show_id) {
      this.setState({channel, show});
    }
  }

  onSelectShow(channel: ChannelInfo, show: ShowInfo) {
    // setup
    const {onNavigate} = this.props;
    const channel_number = this.state.channel?.channel_number;
    const shows = channel ? channel.shows : [];
    const show_id = this.state.show?.id;
    const sIndex = shows.findIndex(obj => obj.id === show_id);

    if (channel.channel_number === channel_number && sIndex === 0) {
      onNavigate(channel.affiliation);
    }
  }

  render()
  {
    // setup
    const {show} = this.state;
    const vTimeframes = this.renderTimeframes();
    const vChannels = this.renderChannels();

    let details = '';
    let timeframe = '';
    if (show) {
      timeframe = show.display_start + ' - ' + show.display_end;
      details = show.episode ? show.episode + ' ': '';
      details += show.rating ? show.rating : '';
    }

    // render
    return <div className='epg'>
      <div className='section epg'>
        <div className='epg-1'>
          <img alt='epg-logo' src={require("../assets/fltv-logo.png")}/>
        </div>
        <div className='epg-2'>
          <text className='title'>{show?.title}</text>
          <text className='small'>{timeframe}</text>
          <text className='small'>{details}</text>
        </div>
        <div className='epg-3'>
          <text>{show?.description}</text>
        </div>
      </div>

      <div className='section table'>
        <table>
        <thead>
          {vTimeframes}
          </thead>
          <tbody>
          {vChannels}
          </tbody>
        </table>
      </div>
    </div>
  }

  renderChannels() {
    // setup
    const {channels} = this.props;
    const channel_number = this.state.channel?.channel_number;
    const show_id = this.state.show?.id;

    // render
    return channels.map(channel => {
      // shows
      const shows = channel.shows;
      const maxCols = 5;
      let date = moment();
      date.minutes(date.minutes() > 30 ? 30 : 0);

      // render the shows
      let cols = 0;
      let vShows = [];
      for (let i = 0; i < shows.length && cols < maxCols; i++) {
        // setup
        const show = shows[i];
        const selected = (channel.channel_number === channel_number && show.id === show_id) ? ' selected' : '';
        const sTime =  moment(show.start_time);
        const eTime =  moment(show.end_time);
        const diff = date.valueOf() < sTime.valueOf() ?
            eTime.valueOf() - sTime.valueOf() :
            eTime.valueOf() - date.valueOf();

        let colspan = Math.round(diff / 1800000);
        if (!colspan) {
          colspan++;
        } else if (cols + colspan > maxCols) {
          colspan = maxCols - cols;
        }
        cols += colspan;

        vShows.push(<td
            className={'program-block' + selected}
            colSpan={colspan}
            onMouseEnter={() => this.onHighlightShow(channel, show)}
            onClick={() => this.onSelectShow(channel, show)}
        >
          <text>{show.title}</text>
        </td>);
      }

      // render the channel
      return <tr>
        <td className='channel'>
          <text className='channel-1'>{channel.channel_number}</text>
          <text className='channel-2'>{channel.affiliation}</text>
        </td>
        {vShows}
      </tr>
    });
  }

  renderTimeframes() {
    // setup
    let date = moment();
    date.minutes(date.minutes() > 30 ? 30 : 0);

    let vTimeframes = [
      <th/>
    ];
    for (let i = 0; i < 5; i++) {
      vTimeframes.push(<th>
          <text>{date.format('h:mmA')}</text>
        </th>);
      date.add(30, 'minutes');
    }
    return vTimeframes;
  }

}
