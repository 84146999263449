// react
import React from 'react';

// local
import ReactHlsPlayer from 'react-hls-player';
import {ChannelInfo, UserInfo} from "../types";
import {Button} from "../components";

import profileImg from "../assets/profile-inactive.svg";
import ccImg from "../assets/cc-inactive.svg";
import audioImg from "../assets/audio-inactive.svg";
import epgImg from "../assets/epg-inactive.svg";

/**
 * IntroScreen
 */

interface ViewProps {
  user: UserInfo,
  channels: ChannelInfo[],
  onNavigate: ( action: string) => void
}
interface ViewState {
  channel: ChannelInfo | undefined
}

export class PlayerScreen extends React.Component<ViewProps, ViewState> {

  playerRef = React.createRef<HTMLVideoElement>();

  constructor(props: ViewProps) {
    super(props);

    // See if we have a current channel
    const {user, channels} = props;
    const channel = channels.find(item => item.affiliation === user.channel);

    this.state = {
      channel
    };
  }

  render()
  {
    // setup
    const {onNavigate} = this.props;
    const {channel} = this.state;
    const show = channel ? channel.shows[0] : null;
    const playerRef = this.playerRef;

    // render
    if (!channel || !show) {
      return <></>
    }
    return <div className='page black'>
      <ReactHlsPlayer
          playerRef={playerRef}
          src={channel.stream}
          width="100%"
          height="auto"
          autoPlay={true}
          controls={true}
      />

      <div className='tickertape'>

        <div className='player-title'>
          <text className='player-channel'>{channel.channel_number}</text>
          <text className='player-affiliation'>{channel.affiliation}</text>
        </div>

        <text className='player-description'>{show.title}</text>

        <div className='actions'>
          <img
              className='player profile'
              alt='epg-profile'
              src={profileImg}
              onClick={() => onNavigate('profile')}
          />
          <img
              className='player cc'
              alt='epg-cc'
              src={ccImg}
              onClick={() => onNavigate('cc')}
          />
          <img
              className='player audio'
              alt='epg-audio'
              src={audioImg}
              onClick={() => onNavigate('audio')}
          />
          <img
              className='player logo'
              alt='epg-epg'
              src={epgImg}
              onClick={() => onNavigate('epg')}
          />
          <Button
              className='player user'
              title='X'
              action='clear-user'
              onClick={() => onNavigate('clear-user')}
          />
        </div>
      </div>

    </div>
  }

}
