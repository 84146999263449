export const SysStr = {
	// Global
	title: 'FREELOCALTV',

	button: {
		agree: 'Agree',
		cancel: 'Cancel',
		delete: 'Delete',
		exit: 'Exit',
		help: '?',
		ok: 'OK',
		start: 'Get Started',
	},
	keyboard: [
		{id: '1', value: '1'},
		{id: '2', value: '2'},
		{id: '3', value: '3'},
		{id: '4', value: '4'},
		{id: '5', value: '5'},
		{id: '6', value: '6'},
		{id: '7', value: '7'},
		{id: '8', value: '8'},
		{id: '9', value: '9'},
		{id: 'delete', value: 'Delete'},
		{id: '0', value: '0'},
		{id: 'enter', value: 'Enter'},
	],

	// Page
	age: {
		title: 'Age Range',
		description: 'Select your age range',
		options: [
			{id: '1', value: '6-11'},
			{id: '2', value: '12-17'},
			{id: '3', value: '18-24'},
			{id: '4', value: '25-34'},
			{id: '5', value: '35-44'},
			{id: '6', value: '45-54'},
			{id: '7', value: '55-64'},
			{id: '8', value: '65+'}
		]
	},
	avatar: {
		title: "Select your avatar"
	},
	gender: {
		title: 'Gender',
		description: 'What best describes you?',
		options: [
			{id: 'm', value: 'Man'},
			{id: 'f', value: 'Woman'},
		]
	},
	help: {
		title: 'FreeLocalTV Help',
		description: "<ol>"
			+ "<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste.</li>"
			+ "<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste.</li>"
			+ "<li>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. Sed ut perspiciatis unde omnis iste.</li>"
			+ "</ol>"
	},
	registered: {
		description: "Streaming local channels from Salt Lake City, Utah.",
		url: "Visit FreeLocalTV.utah.org for details.",
	},
	tos: {
		title: 'Terms of Service',
		description: "<ol>"
			+ "<li>FREELOCALTV: This is a public service owned and operated by the State of Utah and is provided to you at no charge.</li>"
			+ "<li>AGREEMENT: By using this service, you agree to these terms and conditions. If you do not agree, don’t use the service.</li>"
			+ "<li>AUDIENCE: FreeLocalTV is made available to residents and visitors of the State of Utah and is intended for individuals aged 18 or older or those with parental consent.</li>"
			+ "<li>PRIVACY: We do NOT collect any data that is personally identifiable. You agree that the anonymous data we do collect (e.g., zip code, age range, gender) can be licensed to others to cover our costs. You also agree that the anonymous data you provide to us is accurate.</li>"
			+ "<li>PERSONALIZATION: You agree that advertisements may appear during playback and these ads may or may not target your specific demographic.</li>"
			+ "<li>CONTENT: FreeLocalTV is like a virtual retransmission tower. We stream live TV programs as they are broadcast—like what you would see with antenna TV if the over-the-air signal was available and unobstructed.</li>"
			+ "<li>LOCATION: The device that runs the FreeLocalTV app must be located inside the State of Utah (or other participating state) including the applicable local TV boundary.</li>"
			+ "<li>LIMITATIONS: You understand that due to hardware and Internet bandwidth constraints, we may not be able to offer all of the local channels in your market or provide simultaneous access to all users across all devices; especially during abnormally high-peak times. System access is provided and maintained on a first-come, first-served basis.</li>"
			+ "<li>DISCLAIMER: THIS SERVICE IS PROVIDED ON AN “AS-IS” AND “AS-AVAILABLE BASIS, WITHOUT WARRANTIES OR GUARANTEES OF ANY KIND, INCLUDING NONINFRINGEMENT, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND AS TO QUALITY, AVAILABILITY, AND SUBJECT MATTER OF CONTENT. In no event, shall the State of Utah be liable for any damages resulting from the use of or inability to use the service.</li>"
			+ "<li>LIABILITY: YOU ACKNOWLEDGE AND AGREE THAT IF YOU INCUR ANY DAMAGES THAT ARISE OUT FREELOCALTV’s ACTS OR OMISSIONS, THE DAMAGES, IF ANY, ARE NOT IRREPARABLE AND ARE NOT SUFFICIENT TO ENTITLE YOU TO AN INJUNCTION OR OTHER EQUITABLE RELIEF RESTRICTING OPERATION OF THE SERVICES.</li>"
			+ "<li>LAW & FORUM: These terms shall be governed by and construed in accordance with the laws of the State of Utah with exclusive jurisdiction in the state and federal courts in Salt Lake City, Utah.</li>"
			+ "<li>QUESTIONS: For questions regarding these terms, contact us at FreeLocalTV.utah.gov.</li>"
			+ "</ol>"
	},
	unregistered: {
		description: "A public service owned and operated by participating state governments.",
		disclaimer: "We use anonymous demographic data so we can offer the service for free. We collect no personally-identifiable data.",
		url: "Visit FreeLocalTV.org for more information.",
	},
	zipcode: {
		title: 'Zip Code',
		description: 'Enter your zip code',
		invalid: 'The Zip Code you entered does not match the general location of your device. Please verify your Zip Code and try again. Visit FreeLocalTV.org for more information.'
	},
}

export const System = (function () {

	function clearStorage(keyName: string) {
		localStorage.removeItem(keyName);
		sessionStorage.removeItem(keyName);
	}

	function getStorage(keyName: string): any | null {
		let dstData = localStorage.getItem(keyName);
		if (!dstData) {
			dstData = sessionStorage.getItem(keyName);
		}
		return dstData ? JSON.parse(dstData) : null;
	}

	function setStorage(storage: string, keyName: string, srcData: any) {
		let dstData: any;
		if (storage === 'local') {
			const data = localStorage.getItem(keyName);
			if (typeof srcData === 'object') {
				dstData = data ? JSON.parse(data) : {};
				for (let i in srcData) {
					dstData[i] = srcData[i]; // overwrite key-value pairs.
				}
			} else {
				dstData = data ? JSON.parse(data) : [];
				dstData.push(srcData); // append array data.
			}
			localStorage.setItem(keyName, JSON.stringify(dstData));
		} else {
			const data = sessionStorage.getItem(keyName);
			if (typeof srcData === 'object') {
				dstData = data ? JSON.parse(data) : {};
				for (let i in srcData) {
					dstData[i] = srcData[i];
				}
			} else {
				dstData = data ? JSON.parse(data) : [];
				dstData.push(srcData); // append array data.
			}
			sessionStorage.setItem(keyName, JSON.stringify(dstData));
		}
	}

	/*
	 * Public Access
     */
	return {
		clearStorage: clearStorage,
		getStorage: getStorage,
		setStorage: setStorage,
	}

})();
